import { Controller } from "stimulus"

const initializeMDCOptionsMenu = function (context) {
  if (context.hasOptionsTarget) {
    context.optionsMdcMenu = new MDCMenu(context.optionsTarget);
    context.optionsMdcMenu.hoistMenuToBody(); // Not required if the menu is already positioned on the body.
    context.optionsMdcMenu.listen('MDCMenuSurface:opened', function () {
      context.optionsMdcMenu.getOptionByIndex(0).blur();
    });
  }
}

const initializeMDCSurveyTabBars = function (context) {
  if (!context.hasTabBarTarget) {
    return;
  }
  const tabBar = new MDCTabBar(context.tabBarTarget)
  const contentEls = context.tabContentTargets

  tabBar.listen('MDCTabBar:activated', function (event) {
    // Toggle currently-active content
    for (var i = 0; i < contentEls.length; i++) {
      if (event.detail.index == i) {
        contentEls[i].classList.add('mdc-tab-bar__content--active');
      } else {
        contentEls[i].classList.remove('mdc-tab-bar__content--active');
      }
    }
  })
}

export default class extends Controller {
  static targets = ["tabBar", "tabContent", "options"];

  initialize() {
    initializeMDCSurveyTabBars(this)
    initializeMDCOptionsMenu(this)
  }

  toggleOptions(event){
    this.optionsMdcMenu.open = !this.optionsMdcMenu.open;
  }
}
