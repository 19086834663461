import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newDialog", "recipientIdInput", 'userAutocomplete', 'menu'];

  initialize() {
    // Initialize Dialog
    if (this.hasNewDialogTarget) {
      this.dialog = new MDCDialog(this.newDialogTarget)
    }

    // Initialize recipient search autocomplete
    if (this.hasUserAutocompleteTarget) {
      const elem = this.userAutocompleteTarget;
      const recipientIdInput = this.recipientIdInputTarget;
      const data = $(elem).autocomplete({
        minLength: 2,
        source: elem.dataset.url,
        focus: function (event, ui) {
          elem.value = ui.item.display_name;
          return false;
        },
        select: function (event, ui) {
          elem.value = ui.item.display_name;
          recipientIdInput.value = ui.item.id;
          return false;
        }
      }).data("ui-autocomplete");


      return data._renderItem = function (ul, item) {
        return $("<li></li>").data("ui-autocomplete-item", item)
          .append(item.display_name)
          .appendTo(ul);
      }
    }
  }

  validateRecipient(event) {
    const recipientId = this.recipientIdInputTarget.value
    const textField = this.recipientIdInputTarget.closest('.mdc-text-field');
    const fieldContainer = textField.closest('.form-group');

    if (recipientId === null || recipientId.length == 0) {

      if (fieldContainer.querySelector('.mdc-text-field-helper-line') === null) {
            const errorElem = document.createElement("div");
        errorElem.classList.add('mdc-text-field-helper-line');
        errorElem.innerHTML = "<small class='text-danger'>Debes seleccionar un destinatario</small>";
        fieldContainer.appendChild(errorElem);
      }
      event.preventDefault()
    } else {
      if (fieldContainer.querySelector('.mdc-text-field-helper-line') !== null) {
        fieldContainer.querySelector('.mdc-text-field-helper-line').remove();
      }
    }
  }

  showModal(event) {
    const recipientId = event.currentTarget.dataset.recipientId
    const recipientName = event.currentTarget.dataset.recipientName

    if (typeof recipientId !== 'undefined' && recipientId !== null && typeof recipientName !== 'undefined' && recipientName !== null) {
      this.recipientIdInputTarget.value = recipientId
      this.userAutocompleteTarget.value = recipientName
    }

    this.dialog.open();

    event.preventDefault();
  }
}
