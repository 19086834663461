import { Controller } from "stimulus"

const initializeMDCRipples = function () {
  const selector = '.mdc-button, .mdc-fab, .mdc-card__primary-action';
  const ripples = [].map.call(document.querySelectorAll(selector), function (el) {
    return new MDCRipple(el);
  });
}

const initializeMDCChipsets = function () {
  const chipSets = document.querySelectorAll('.mdc-chip-set');
  for (const chipSet of chipSets) {
    new MDCChipSet(chipSet);
  }
}

const initializeMDCSnackbar = function () {
  const snackbars = document.querySelectorAll('.mdc-snackbar')
  for (const snackbar of snackbars) {
    new MDCSnackbar(snackbar).open();
  }
};

const initializeMDCLists = function () {
  const mdcLists = document.querySelectorAll('.mdc-list');
  for (const mdcList of mdcLists) {
    new MDCList(mdcList);
  }
};

const initializeMDCTextFields = function () {
  const textFields = document.querySelectorAll('.mdc-text-field');
  for (const textField of textFields) {
    MDCTextField.attachTo(textField);
  }
}

const initializeMDCRadioButtons = function () {
  const formFieldElems = document.querySelectorAll('.jsRadioFormField');
  for (const formFieldElem of formFieldElems) {
    const formField = new MDCFormField(formFieldElem);
    const radio = new MDCRadio(formFieldElem.querySelector('.mdc-radio'));
    formField.input = radio;
  }
}
const initializeMDCCheckboxButtons = function () {
  const formFieldElems = document.querySelectorAll('.jsCheckboxFormField');
  for (const formFieldElem of formFieldElems) {
    const formField = new MDCFormField(formFieldElem);
    const checkbox = new MDCCheckbox(formFieldElem.querySelector('.mdc-checkbox'));
    formField.input = checkbox;
  }
}

const initializeMDCSelects = function () {
  const selects = document.querySelectorAll('.mdc-select');

  for (const select of selects) {
    new MDCSelect(select);
  }
}

const initializeTooltips = function () {
  const tooltipElems = document.querySelectorAll('[data-toggle=tooltip]');
  for (const tooltipElem of tooltipElems) {
    const placement = typeof tooltipElem.dataset.placement == 'undefined' ? 'top' : tooltipElem.dataset.placement;
    const size = typeof tooltipElem.dataset.size == 'undefined' ? 'regular' : tooltipElem.dataset.size;

    tippy(tooltipElem, {
      content: tooltipElem.dataset.tooltip,
      allowHTML: true,
      placement: placement,
      size: size,
      animateFill: false
    })
  }
}

export default class extends Controller {
  static targets = [];

  initialize() {
    initializeMDCSnackbar();
    initializeMDCTextFields();
    initializeMDCLists();
    initializeMDCChipsets();
    initializeMDCRadioButtons();
    initializeMDCCheckboxButtons();
    initializeMDCSelects();
    initializeMDCRipples();
    initializeTooltips();
  }
}
