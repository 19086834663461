import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["post", "fabButton", "postsCount"];

  initialize() {
    this.quotedPostIds = []
  }

  reply(event) {
    if (this.hasFabButtonTarget) {
      window.location = event.currentTarget.dataset.url + '?quoted_post_id=' + this.quotedPostIds;

      event.preventDefault();
    }
  }

  togglePost(event) {
    const postId = event.currentTarget.dataset.id

    if (this.quotedPostIds.includes(postId)) {
      this.quotedPostIds.splice(this.quotedPostIds.indexOf(postId), 1)
      event.currentTarget.classList.remove('active')
    } else {
      this.quotedPostIds.push(postId)
      event.currentTarget.classList.add('active')
    }

    this.postsCountTarget.textContent = this.quotedPostIds.length
    if (this.quotedPostIds.length > 0) {
      this.fabButtonTarget.style.display = 'inline-flex'
    } else {
      this.fabButtonTarget.style.display = 'none'
    }

    event.preventDefault();
  }
}
