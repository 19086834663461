import { Controller } from "stimulus"

const initializeMDCPostOptionsMenu = function (context) {
  if (context.hasOptionsTarget) {
    context.optionsMdcMenu = new MDCMenu(context.optionsTarget);
    context.optionsMdcMenu.hoistMenuToBody(); // Not required if the menu is already positioned on the body.
    context.optionsMdcMenu.listen('MDCMenuSurface:opened', function () {
      context.optionsMdcMenu.getOptionByIndex(0).blur();
    });
  }
}

const initializeMDCPostReactionsMenu = function (context) {
  if (context.hasReactionsMenuTarget) {
    context.reactionsMdcMenu = new MDCMenu(context.reactionsMenuTarget);
  }
}

export default class extends Controller {
  static targets = ["options", "reactionsMenu", "postContent"];

  initialize() {
    initializeMDCPostOptionsMenu(this)
    initializeMDCPostReactionsMenu(this)
  }

  toggleOptions(event){
    this.optionsMdcMenu.open = !this.optionsMdcMenu.open;
  }

  toggleReactionsMenu(event){
    this.reactionsMdcMenu.open = !this.reactionsMdcMenu.open;
  }

  reactToPost(event) {
    event.currentTarget.closest('form').submit();
  }

  togglePostContent(event) {
    if (this.postContentTarget.classList.contains('collapse')) {
      this.postContentTarget.classList.remove('collapse');
    } else {
      this.postContentTarget.classList.add('collapse');
    }
  }
}
