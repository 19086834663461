import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["appendable"]

  addField(event) {
    const new_id = new Date().getTime()
    const regexp = new RegExp("new_" + this.appendableTarget.dataset.association, "g")
    const node = document.
      createRange().
      createContextualFragment(this.appendableTarget.dataset.template.replace(regexp, new_id))

    this.appendableTarget.appendChild(node)

    // The text fields added dynamically haven't been initialize,
    // that's why we have to do it manually
    const textFields = this.appendableTarget.querySelectorAll('.mdc-text-field')
    for (const textField of textFields) {
      MDCTextField.attachTo(textField)
    }

    event.preventDefault()
  }
}
