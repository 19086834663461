require('summernote/dist/summernote-lite');
require('summernote/dist/lang/summernote-es-ES.min');
require('summernote/dist/font/summernote.eot');
require('summernote/dist/font/summernote.woff');
require('summernote/dist/font/summernote.ttf');
require('summernote/dist/summernote-lite.css');

import { Controller } from "stimulus"

const BlockquoteButton = function (context) {
  const ui = $.summernote.ui;

  const button = ui.button({
    contents: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
    tooltip: 'Cita',
    click: function () {
      context.invoke('editor.formatBlock', 'Blockquote');
    }
  });

  return button.render();
}

const SpoilerButton = function (context) {
  const ui = $.summernote.ui;

  const button = ui.button({
    contents: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3V1h-2v4H8l4 4 4-4zM4 11v2h16v-2H4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
    tooltip: 'Spoiler',
    click: function () {
      const details = document.createElement('details')
      details.open = true;
      details.innerHTML = '<summary contenteditable="false" readonly="readonly">Spoiler</summary><p contenteditable="true">Inserta spoiler aquí</p>';
      context.invoke('editor.insertNode', details);
      context.invoke('editor.pasteHTML', '<br>');
    }
  });

  return button.render();
}

const userMentionSearchCallback = function (keyword, callback) {
  return $.get(
    '/users',
    {
      term: keyword,
      format: 'json'
    }, callback
  )
}

const enableSummernote = function (elem) {
  if ($(elem).is(":visible")) {
    $(elem).summernote(
      {
        lang: 'es-ES',
        minHeight: 200,
        popover: {},
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontsize', ['fontsize']],
          ['para', ['blockquote', 'ul', 'ol', 'paragraph', 'spoiler']],
          ['multimedia', ['link', 'picture', 'video']],
        ],
        disableDragAndDrop: true,
        placeholder: $(this).prop('placeholder'),
        buttons: {
          blockquote: BlockquoteButton,
          spoiler: SpoilerButton
        },
        hint: {
          match: /\B@(\w\w\w*)$/,
          users: userMentionSearchCallback,
          search: function (keyword, callback) {
            this.users(keyword, callback);
          },
          template: function (item) {
            return item.display_name;
          },
          content: function (item) {
            const elem = '<a href="' + item.url + '" data-mention-id=' + item.id + '>' + item.display_name + '</a>';
            return $(elem)[0];
          }
        }
      }
    )
  }
};

export default class extends Controller {
  static targets = ["contentEditor"];

  connect() {
    enableSummernote(this.contentEditorTarget);
  }
}
