import { Controller } from "stimulus"

const initializeMDCOptionsMenu = function (context) {
  if (context.hasOptionsTarget) {
    context.optionsMdcMenu = new MDCMenu(context.optionsTarget);
    context.optionsMdcMenu.hoistMenuToBody(); // Not required if the menu is already positioned on the body.
    context.optionsMdcMenu.listen('MDCMenuSurface:opened', function () {
      context.optionsMdcMenu.getOptionByIndex(0).blur();
    });
  }
}

export default class extends Controller {
  static targets = ["options"];

  initialize() {
    initializeMDCOptionsMenu(this)
  }

  toggleOptions(event){
    this.optionsMdcMenu.open = !this.optionsMdcMenu.open;
  }
}
