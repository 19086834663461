import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["options"];

  toggleOptions(event) {
    const currentValue = event.currentTarget.value

    for (const target of this.optionsTargets) {
      if (target.dataset.type == currentValue) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    }
  }
}
