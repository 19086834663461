import { Controller } from "stimulus"

const initializeConversationsMDCMenu = function (context) {
  if (context.hasConversationsMenuTarget) {
    context.conversationsMenu = new MDCMenu(context.conversationsMenuTarget);

    // Do not activate first elem when opening
    context.conversationsMenu.listen('MDCMenuSurface:opened', function () {
      context.conversationsMenu.getOptionByIndex(0).blur();
    });
  }
};

const initializeNotificationsMDCMenu = function (context) {
  if (context.hasNotificationsMenuTarget) {
    context.notificationsMenu = new MDCMenu(context.notificationsMenuTarget);

    // Do not activate first elem when opening
    context.notificationsMenu.listen('MDCMenuSurface:opened', function () {
      context.notificationsMenu.getOptionByIndex(0).blur();
    });
  }
};

const initializeMDCTopAppBar = function (context) {
  context.mdcTopBar = new MDCTopAppBar(context.topAppBarTarget);
  context.mdcTopBar.setScrollTarget(context.mainContentTarget);
  context.mdcTopBar.listen('MDCTopAppBar:nav', () => {
    context.mdcDrawer.open = !context.mdcDrawer.open;
  });
};

const initializeMDCDrawer = function (context) {
  if (context.hasDrawerTarget) {
    context.mdcDrawer = new MDCDrawer(context.drawerTarget);
  }
};

const initializeTermsDialog = function (context) {
  if (context.hasTermsDialogTarget) {
    context.termsMDCDialog = new MDCDialog(context.termsDialogTarget);
    if (typeof context.termsDialogTarget.dataset.autoload !== "undefined" && context.termsDialogTarget.dataset.autoload === "true") {
      context.termsMDCDialog.open();
    }
  }
}

export default class extends Controller {
  static targets = [
    'conversationsMenu',
    'notificationsMenu',
    'topAppBar',
    'mainContent',
    'drawer',
    'termsDialog'
  ];

  initialize() {
    initializeMDCDrawer(this);
    initializeConversationsMDCMenu(this);
    initializeNotificationsMDCMenu(this);
    initializeMDCTopAppBar(this);
    initializeTermsDialog(this);
  }

  toggleConversationsMenu(event) {
    if (this.hasConversationsMenuTarget) {
      this.conversationsMenu.open = !this.conversationsMenu.open;
    }
    event.preventDefault();
  }

  toggleNotificationsMenu(event) {
    if (this.hasNotificationsMenuTarget) {
      this.notificationsMenu.open = !this.notificationsMenu.open;
    }
    event.preventDefault();
  }

  toggleDrawer(event) {
    this.mdcDrawer.open = !this.mdcDrawer.open;
  }

  toggleTermsDialog(event) {
    this.termsMDCDialog.open();
  }
}
