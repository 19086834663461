require('summernote/dist/summernote-lite');
require('summernote/dist/lang/summernote-es-ES.min');
require('summernote/dist/font/summernote.eot');
require('summernote/dist/font/summernote.woff');
require('summernote/dist/font/summernote.ttf');
require('summernote/dist/summernote-lite.css');

import { Controller } from "stimulus"

const enableSummernote = function (elem) {
  if ($(elem).is(":visible")) {
    $(elem).summernote(
      {
        lang: 'es-ES',
        minHeight: 200,
        popover: {},
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontsize', ['fontsize']],
          ['para', ['blockquote', 'ul', 'ol', 'paragraph', 'spoiler']],
          ['multimedia', ['link', 'picture', 'video']],
        ],
        disableDragAndDrop: true,
        placeholder: $(this).prop('placeholder')
      }
    )
  }
};

export default class extends Controller {
  static targets = ["contentEditor"];

  initialize() {
    enableSummernote(this.contentEditorTarget);
  }
}
