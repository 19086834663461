import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  remove(event) {
    event.stopImmediatePropagation()
    this.fieldTarget.remove()
  }
}
