/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

import { MDCSnackbar }   from '@material/snackbar';
import { MDCTextField }  from '@material/textfield';
import { MDCMenu }       from '@material/menu';
import { MDCDrawer }     from "@material/drawer";
import { MDCList }       from '@material/list';
import { MDCTopAppBar }  from '@material/top-app-bar';
import { MDCRipple }     from '@material/ripple';
import { MDCChipSet }    from '@material/chips';
import { MDCFormField }  from '@material/form-field';
import { MDCRadio }      from '@material/radio';
import { MDCCheckbox }   from '@material/checkbox';
import { MDCTabBar }     from '@material/tab-bar';
import { MDCDialog }     from '@material/dialog';
import { MDCSelect }     from '@material/select';
import tippy             from 'tippy.js';

import 'webpack-jquery-ui/autocomplete';

Rails.start();
Turbolinks.start();

window.MDCSnackbar = MDCSnackbar;
window.MDCTextField = MDCTextField;
window.MDCMenu = MDCMenu;
window.MDCDrawer = MDCDrawer;
window.MDCList = MDCList;
window.MDCTopAppBar = MDCTopAppBar;
window.MDCRipple = MDCRipple;
window.MDCChipSet = MDCChipSet;
window.MDCFormField = MDCFormField;
window.MDCRadio = MDCRadio;
window.MDCCheckbox = MDCCheckbox;
window.MDCTabBar = MDCTabBar;
window.MDCDialog = MDCDialog;
window.MDCSelect = MDCSelect;
window.tippy = tippy;

require('./stylesheets/application');

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

console.log('Hola Real Foristas!');
